import { useState } from "react";
import "@pqina/pintura/pintura.css";

// Import the editor functionality
import {
  // Import the default image reader and writer
  createDefaultImageReader,
  createDefaultImageWriter,

  // The method used to register the plugins
  setPlugins,

  // The plugins we want to use
  plugin_crop,
  plugin_finetune,
  plugin_annotate,

  // The user interface and plugin locale objects
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_annotate_locale_en_gb,

  // Because we use the annotate plugin we also need
  // to import the markup editor locale and the shape preprocessor
  markup_editor_locale_en_gb,
  createDefaultShapePreprocessor,

  // Import the default configuration for the markup editor and finetune plugins
  markup_editor_defaults,
  plugin_finetune_defaults,
} from "@pqina/pintura";

// Import the editor component from `react-pintura`
import { PinturaEditor } from "@pqina/react-pintura";

export default function ImageEditor() {
  setPlugins(plugin_crop, plugin_finetune, plugin_annotate);

  // Create our editor configuration
  const editorConfig = {
    // This will read the image data (required)
    imageReader: createDefaultImageReader(),

    // This will write the output image
    imageWriter: createDefaultImageWriter(),

    // The markup editor default options, tools, shape style controls
    ...markup_editor_defaults,

    // The finetune util controls
    ...plugin_finetune_defaults,

    // This handles complex shapes like arrows / frames
    shapePreprocessor: createDefaultShapePreprocessor(),

    // This will set a square crop aspect ratio
    imageCropAspectRatio: 1,

    // The icons and labels to use in the user interface (required)
    locale: {
      ...locale_en_gb,
      ...plugin_crop_locale_en_gb,
      ...plugin_finetune_locale_en_gb,
      ...plugin_annotate_locale_en_gb,
      ...markup_editor_locale_en_gb,
    },
  };
  const [editImage, setEditImage] = useState(null);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setEditImage(URL.createObjectURL(file));
  };
  return (
    <div className="App" style={{ height: "600px" }}>
      <input type="file" onChange={handleImageUpload} />
      <PinturaEditor
        {...editorConfig}
        src={editImage}
        imageCropAspectRatio={1}
      ></PinturaEditor>
    </div>
  );
}

