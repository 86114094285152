import React, { useState, useContext, useRef, useEffect } from "react";
import { Button, Form, Popover, Overlay } from "react-bootstrap";
import { RiPlayListAddFill } from "react-icons/ri";
import { toast } from "react-toastify";
import CategoryPopover from "../components/CategoryPopover";
import { addCategory, addMenuItems, updateMenuItem } from "../apis";
import AuthContext from "../contexts/AuthContext";
import ImageDropzone from "./ImageDropzone";
import LanguageTabs from "../components/LanguageTabs";
import Select from "react-select";
import ImageEditor from "../components/ImageEditor";

const MenuItemForm = ({
  place,
  onDone,
  item,
  bgImage,
  setBgImage,
  categoryPriority,
  setCategoryPriority,
  mainCategories,
  categoryIsAvailable,
  setCategoryIsAvailable,
  featuredItemOptions,
  tags,
}) => {
  const [categoryFormShow, setCategoryFormShow] = useState(false);
  const [parent_category, setParentCategory] = useState("");
  const [category, setCategory] = useState(item.category || {});
  const [itemPriority, setItemPriority] = useState(item.priority);
  const [price, setPrice] = useState(item.price || 0);
  const [image, setImage] = useState(item.image);
  const [clearInputs, setClearInputs] = useState(false);
  const [isAvailable, setIsAvailable] = useState(
    item.is_available === undefined ? true : !!item.is_available
  );
  const [variantArray, setVariantArray] = useState(item.variants);
  const [dishArray, setDishArray] = useState(item.side_dish ?? []);

  const formattedFeaturedItems =
    item.featured_items &&
    item.featured_items.map((item) => ({
      value: item.featured_item.id,
      label: item.featured_item.name,
    }));

  const [featuredItems, setFeaturedItems] = useState(
    formattedFeaturedItems ?? []
  );

  const [selectedDishIndex, setSelectedDishIndex] = useState(-1);

  const [item_type, setItemType] = useState(item.type);

  const formattedTag = item.tag && {
    value: item.tag.id,
    label: item.tag.name,
  };

  const [selectedTag, setSelectedTag] = useState(formattedTag ?? "");

  const target = useRef(null);
  const auth = useContext(AuthContext);

  const formattedFeaturedItemOptions =
    featuredItemOptions &&
    featuredItemOptions.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const formattedTagOptions =
    tags &&
    tags.map((item) => ({
      value: item.id,
      label: (
        <div
          style={{
            background: item?.color ? item?.color : "transparent",
            paddingLeft: "10px",
            paddingRight: "10px",
            color: "white",
            width: "fit-content",
            gap: "5px",
            fontSize: "12px",
            borderRadius: "6px",
            lineHeight: "12px",
            paddingBottom: "3px",
            paddingTop: "3px",
          }}
          className="d-flex align-items-center justify-content-between "
        >
          {item?.name}
          <img
            src={item?.icon}
            alt="icon"
            style={{ width: "12px", height: "12px" }}
          />
        </div>
      ),
    }));

  const onAddCategory = async () => {
    const json = await addCategory(
      {
        priority: categoryPriority,
        parent_category: parent_category,
        background_image: bgImage,
        ...category,
        place: place.id,
      },
      auth.token
    );

    if (json) {
      toast(`Category ${json.name} was created.`, { type: "success" });
      setParentCategory(null);
      setBgImage(json.background_image);
      setCategoryPriority(json.priority);
      setCategoryFormShow(false);
      onDone();
    }
  };

  const onAddMenuItems = async () => {
    const json = await addMenuItems(
      {
        place: place.id,
        ...item,
        category: category,
        price: price,
        priority: itemPriority,
        image,
        is_available: isAvailable,
        type: item_type,
        variants: variantArray,
        side_dish: dishArray,
        tag: selectedTag.value,
        featured_items: featuredItems?.map((item) => item.value),
      },
      auth.token
    );

    if (json) {
      toast(`Menu Item ${json.name} was created`, { type: "success" });
      setCategory("");
      setPrice(0);
      setImage("");
      setVariantArray([]);
      setDishArray([]);
      setItemType("");
      setIsAvailable(true);
      setItemPriority(0);
      setClearInputs(true);
      onDone();
    }
  };

  const onUpdateMenuItem = async () => {
    const json = await updateMenuItem(
      item.id,
      {
        place: place.id,
        ...item,
        category: category,
        priority: itemPriority,
        price: price,
        image,
        is_available: isAvailable,
        type: item_type,
        variants: variantArray,
        side_dish: dishArray,
        tag: selectedTag?.value !== null ? selectedTag?.value : null,
        featured_items: featuredItems?.map((item) => item.value),
      },
      auth.token
    );
    if (json) {
      toast(`Menu Item ${json.name} was updated`, { type: "success" });
      setItemPriority(json.priority);
      setCategory(json.category);
      setPrice(json.price);
      setItemType(json.type);
      setImage("");
      onDone();
    }
  };
  useEffect(() => {
    item.price = price;
    item.category = category;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, category]);

  function renderCategories(categories, indentation = "") {
    return categories.map((category) => (
      <option key={category.id} value={category.id}>
        {`${indentation} ${category.name_bs}`}
      </option>
    ));
  }

  function renderNestedCategories(categories, indentation = "") {
    return categories.map((category) => (
      <React.Fragment key={category.id}>
        {renderCategories([category], indentation)}
        {category.children &&
          renderNestedCategories(category.children, `${indentation}-`)}
      </React.Fragment>
    ));
  }

  return (
    <div>
      {/* CATEGORIES FORM */}
      <Form.Group>
        <ImageEditor />
        <Form.Label>Category</Form.Label>
        <div className="d-flex align-items-center">
          <Form.Control
            as="select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <>
              <option>Main category</option>
              {mainCategories && renderNestedCategories(mainCategories)}
            </>
          </Form.Control>

          <Button
            ref={target}
            variant="link"
            onClick={() => {
              setCategoryFormShow(true);
              setCategory({});
            }}
          >
            <RiPlayListAddFill size={25} />
          </Button>
          <Overlay
            show={categoryFormShow}
            target={target.current}
            placement="bottom"
            rootClose
            onHide={() => setCategoryFormShow(false)}
          >
            <Popover id="popover-contained">
              <Popover.Title as="h3">Category</Popover.Title>
              <Popover.Content>
                <CategoryPopover
                  onAddCategory={onAddCategory}
                  item={item}
                  category={category}
                  bgImage={bgImage}
                  setBgImage={setBgImage}
                  categoryPriority={categoryPriority}
                  setCategoryPriority={setCategoryPriority}
                  place={place}
                  parent_category={parent_category}
                  setParentCategory={setParentCategory}
                  categoryIsAvailable={categoryIsAvailable}
                  setCategoryIsAvailable={setCategoryIsAvailable}
                />
              </Popover.Content>
            </Popover>
          </Overlay>
        </div>
      </Form.Group>

      {/* MENU ITEMS FORM */}
      <LanguageTabs
        place={place}
        key={item.id}
        item={item}
        clearInputs={clearInputs}
        setItemPriority={setItemPriority}
        setClearInputs={setClearInputs}
        dishArray={dishArray}
        setDishArray={setDishArray}
        selectedDishIndex={selectedDishIndex}
        setSelectedDishIndex={setSelectedDishIndex}
        variantArray={variantArray}
        setVariantArray={setVariantArray}
        price={price}
        setPrice={setPrice}
      />
      <Form.Group>
        <Form.Label>Select Featured Items</Form.Label>
        <Select
          isMulti
          options={formattedFeaturedItemOptions}
          value={featuredItems}
          onChange={(selected) => setFeaturedItems(selected)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Select Tag</Form.Label>
        <Select
          isClearable
          options={[
            { label: "Select Tag", value: null },
            ...formattedTagOptions,
          ]}
          value={selectedTag || { label: "Select Tag", value: null }}
          onChange={(selected) => setSelectedTag(selected ? selected : null)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label> Select type </Form.Label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios1"
            value="bar"
            checked={item_type === "bar"}
            onChange={(e) => setItemType(e.target.value)}
          />
          <label className="form-check-label" htmlFor="exampleRadios1">
            Bar
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios2"
            value="kitchen"
            checked={item_type === "kitchen"}
            onChange={(e) => setItemType(e.target.value)}
          />
          <label className="form-check-label" htmlFor="exampleRadios2">
            Kitchen
          </label>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Image</Form.Label>
        <ImageDropzone value={image} onChange={setImage} />
      </Form.Group>
      <Form.Group>
        <Form.Check
          type="checkbox"
          label="Is available?"
          checked={isAvailable}
          onChange={(e) => setIsAvailable(e.target.checked)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Priority</Form.Label>
        <Form.Control
          className="priority"
          type="number"
          placeholder="If you set priority to 1, product shows on the top"
          defaultValue={itemPriority}
          value={itemPriority}
          onChange={(e) => setItemPriority(e.target.value)}
        />
      </Form.Group>

      <Button
        variant="standard"
        block
        onClick={item.id ? onUpdateMenuItem : onAddMenuItems}
      >
        {item.id ? "Update Menu Item" : "+ Add Menu Item"}
      </Button>
    </div>
  );
};

export default MenuItemForm;

